.landingPageWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 40px;
  max-height: 100vh;
  @media (max-width: 430px) {
    padding-bottom: 110px;
  }

  /* CSS specific to iOS devices */ 
  @media (max-width: 430px) {
    @supports (-webkit-touch-callout: none) {
      padding-bottom: 170px;
      overflow: hidden;
    }
  }
}

.PlayerWrapperAbout {
    flex-basis: 25%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

.playWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  margin-inline: auto;
  align-items: center;
  gap: 10px;
  padding-inline: 7px;
  cursor: pointer;
  img {
    width: 120px;
    height: 120px;
  }
  span {
    color: #CC9933;
    font-size: 20px;
    font-weight: 400;
  }
}

  .playerWrapperTitle {
    color: #8F8989;
    font-weight: 400;
    font-size: 40px;
    margin-bottom: 5px;
  }
  
  .playerWrapperSubtitle {
    color: #8F8989;
    font-weight: 400;
    font-size: 17px;
  }
  
  .playerWrapperQuote {
    color: #CC9933;
    font-size: 20px;
    font-weight: 400;
    margin-top: 15px;
    padding: 0;
  }
  
  .footerButton {
    display: flex;
    justify-content: center;
    background: transparent;
    border: none;
    color: #CC9933;
    font-size: 18px;
    cursor: pointer;
    span {
      display: flex;
      gap: 5px;
    }
  }